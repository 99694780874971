<template>
</template>

<script>
const CryptoJS = require("crypto-js");

export default {
    components:{
    },
    data(){
        return{
            code : this.$route.params.code
            // front ui
        }
    },
    mounted(){
        this.Confirm()
    },
    methods:{
        Confirm(){
            const code =  this.code;
            const body = {code};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.$http.post('/member/mailing/confirmPassword',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            this.$router.push(`/reset-pw/${code}`)
                            return false;
                        }else if(res.data.code =="100"){
                            this.$pp({
                                msg: 'This link has already been used.', 
                                is_confirm: false, 
                                auto_hide:true
                            })
                            this.$router.push(`/signin`)
                            return false;
                        }
                    }
                }   
            )
            
        },
    }

}
</script>
<style scoped>
    .code_box input{
        line-height: 48px;
    }
</style>